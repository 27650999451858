import React from "react";
import { Link } from 'gatsby'
import { Jumbotron } from "../components/Jumbotron";
import { Row, Col, Modal, Button  } from "react-bootstrap";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";
import Layout from "../components/Layout";
import "../assets/css/Section.css";
import BackgroundImage from "gatsby-background-image";
import { graphql, StaticQuery } from "gatsby";
import CallBackForm from "../components/CallBackForm";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        bbisHero4: file(relativePath: { eq: "Hero/bbis-hero-4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.bbisHero4.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

export default () => (
  <Layout>
    <SEO
      title="Commercial Heating repairs, Installation and Servicing in Barnet"
      description="BBIS Commercial Heating provides engineers in Barnet and other areas that cover all aspects of heating &amp; hot water. Working with commercial heating systems &amp; domestic heating maintenance"
      keywords=""
    />
    <BackgroundSection>
      <Jumbotron>
        <ul className="pt-4 jumbo-list">
          <h1 class="jumbo-heading">Commercial heating repairs, installation and servicing in Barnet</h1>
        </ul>
        <Modal backdrop="static">
        <CallBackForm />
      </Modal>
      </Jumbotron>
    </BackgroundSection>
    <div class="white-segment">
      <h2 class="heading-color pb-2">Looking for commercial heating repairs, installation and servicing in Barnet</h2>
      <h3 class="pb-2">We repair, service and install a wide range of electric, gas, LPG and oil
            boilers</h3>
      <Modal backdrop="static">
        <CallBackForm />
      </Modal>
      <h4 class="pb-2">Commercial heating boiler service</h4>
      <p>Ensuring your regularly servicing your commercial boiler is really important to ensure it is running as safely and
         efficiently as possible, our team cover Barnet and the surrounding areas. When your boiler is serviced, it will highlight any possible problems that can be
          fixed before a breakdown occurs, ensuring it’s always optimised and won't let you down.
      </p>
      <h4 class="pb-2">Commercial heating boiler installation</h4>
      <p>Looking for a new boiler for your commercial premises? Older boilers become increasingly less energy efficient and costly to run.
        It maybe the time to have a new boiler fitted if you are having regular breakdowns
          and repairs to your central heating system. We can help you through the boiler installation process and operate in Barnet area.
      </p>
      <h4 class="pb-2">Commercial heating boiler repair</h4>
      <p>We understand the inconvenience of your premises being without heating or hot water, and how detrimental it will be for
         the smooth running of your business. We will always aim to get to you the same day (or next working day if same day is not possible).
      </p>
      <p>
        For a professional heating service contact us today on{" "}
        <strong>
          <a href="tel:+448002289800">0800 2289800 </a>
        </strong>
         or request a call back by using this form
      </p>
      {/* <Button onClick={handleShow}>Request Callback</Button>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <CallBackForm handleClose={handleClose} />
      </Modal> */}
    </div>
  </Layout>
);
